import { useState } from 'react';
import { Search, UserPlus, User } from 'lucide-react';

const App = () => {
  const [searchUsername, setSearchUsername] = useState('');
  const [showAddForm, setShowAddForm] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [formData, setFormData] = useState({
    username: '',
    firstName: '',
    lastName: '',
    phone: '',
    instagram: '',
    snapchat: '',
    email: ''
  });

  const handleSearch = async () => {
    try {
      const response = await fetch(`https://localhost:3001/api/users/${searchUsername}`);
      const data = await response.json();
      setUserInfo(data);
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://localhost:3001/api/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      setUserInfo(data);
      setShowAddForm(false);
    } catch (error) {
      console.error('Error adding user:', error);
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-md p-6">
        <h1 className="text-2xl font-bold text-center mb-8">User Directory</h1>
        
        {/* Search Section */}
        <div className="mb-8">
          <div className="flex gap-4 mb-4">
            <input
              type="text"
              placeholder="Search username..."
              className="flex-1 p-2 border rounded"
              value={searchUsername}
              onChange={(e) => setSearchUsername(e.target.value)}
            />
            <button
              onClick={handleSearch}
              className="bg-blue-500 text-white px-4 py-2 rounded flex items-center gap-2 hover:bg-blue-600"
            >
              <Search size={20} />
              Search
            </button>
          </div>
          
          <button
            onClick={() => setShowAddForm(!showAddForm)}
            className="w-full bg-green-500 text-white px-4 py-2 rounded flex items-center justify-center gap-2 hover:bg-green-600"
          >
            <UserPlus size={20} />
            Add New User
          </button>
        </div>

        {/* Add User Form */}
        {showAddForm && (
          <form onSubmit={handleSubmit} className="space-y-4 mb-8">
            <div className="grid grid-cols-2 gap-4">
              <input
                type="text"
                name="username"
                placeholder="Username"
                className="p-2 border rounded col-span-2"
                onChange={handleInputChange}
                required
              />
              <input
                type="text"
                name="firstName"
                placeholder="First Name"
                className="p-2 border rounded"
                onChange={handleInputChange}
                required
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                className="p-2 border rounded"
                onChange={handleInputChange}
                required
              />
              <input
                type="tel"
                name="phone"
                placeholder="Phone Number"
                className="p-2 border rounded"
                onChange={handleInputChange}
                required
              />
              <input
                type="text"
                name="instagram"
                placeholder="Instagram"
                className="p-2 border rounded"
                onChange={handleInputChange}
              />
              <input
                type="text"
                name="snapchat"
                placeholder="Snapchat"
                className="p-2 border rounded"
                onChange={handleInputChange}
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                className="p-2 border rounded col-span-2"
                onChange={handleInputChange}
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
            >
              Save User
            </button>
          </form>
        )}

        {/* User Info Display */}
        {userInfo && (
          <div className="border rounded p-4">
            <div className="flex items-center gap-2 mb-4">
              <User size={24} className="text-gray-600" />
              <h2 className="text-xl font-semibold">{userInfo.username}</h2>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <p><strong>First Name:</strong> {userInfo.firstName}</p>
              <p><strong>Last Name:</strong> {userInfo.lastName}</p>
              <p><strong>Phone:</strong> {userInfo.phone}</p>
              <p><strong>Instagram:</strong> {userInfo.instagram}</p>
              <p><strong>Snapchat:</strong> {userInfo.snapchat}</p>
              <p><strong>Email:</strong> {userInfo.email}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default App;